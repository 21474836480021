<template>
  <v-container v-if="trip">
    <v-row>
      <v-col cols="12">
        <h2
          class="tw-text-center tw-font-figtree tw-text-lg tw-font-semibold tw-text-dark-green"
        >
          Mark your calendars!
        </h2>
        <v-expansion-panels
          v-model="isOpen"
          class="tw-rounded-md tw-mt-4"
          ref="datePanel"
          v-if="isOwner || isOrganizer"
        >
          <v-expansion-panel ref="datesPanel">
            <v-expansion-panel-header>
              <div
                class="j-panel-header tw-flex tw-flex-row tw-items-center tw-justify-between"
              >
                <v-icon>mdi-calendar-blank</v-icon>
                <div
                  class="tw-text-charcoal tw-text-base tw-font-semibold tw-mx-auto"
                >
                  <span v-if="loadedDates && dates.length > 0">{{
                    getFormattedDateRange(dates[0], dates[1])
                  }}</span>
                  <span v-else>Choose a date</span>
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-date-picker
                @change="closePanel"
                class="tw-py-4"
                no-title
                color="secondary"
                v-model="dates"
                range
                :min="minDate"
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="secondary"
                  @click="dates = []"
                  class="tw-tracking-normal"
                  >Reset Dates</v-btn
                >
              </v-date-picker>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div
          class="tw-flex tw-justify-center tw-items-center tw-my-8 tw-border-solid tw-border-t-2 tw-border-b-2"
          v-else
        >
          <v-icon>mdi-calendar-blank</v-icon>
          <p
            class="tw-text-left tw-text-22 tw-font-figtree tw-text-charcoal tw-my-0 tw-ml-4 tw-py-4"
          >
            {{ getFormattedDateRange(dates[0], dates[1]) }}
          </p>
        </div>

        <div
          class="tw-flex tw-justify-center tw-items-center tw-w-200 tw-h-200 tw-bg-j-pink tw-flex-col tw-rounded-full tw-my-10 tw-mx-auto tw-p-8"
          v-if="loadedDates && dates.length > 0"
        >
          <span
            class="tw-text-center tw-font-bebas-neue tw-text-124 tw-text-white tw--mt-8"
            >{{ daysToGo }}</span
          >
          <span
            class="tw-text-center tw-font-figtree tw-text-22 tw-font-semibold tw-text-white tw--mt-14"
            >{{ daysToGo === 1 ? "day" : "days" }} to go</span
          >
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <p
          class="tw-text-left tw-font-figtree tw-font-semibold tw-text-charcoal"
        >
          Don't forget to:
        </p>
        <div class="tw-pl-1">
          <div class="tw-flex tw-items-center">
            <img
              src="@/assets/svgs/dates/checkmark.svg"
              alt="Checkmark"
              class="tw-mr-2"
            />
            <span class="tw-text-left tw-font-figtree tw-text-light-grey"
              >Block your calendar</span
            >
          </div>
          <div class="tw-flex tw-items-center">
            <img
              src="@/assets/svgs/dates/checkmark.svg"
              alt="Checkmark"
              class="tw-mr-2"
            />
            <span class="tw-text-left tw-font-figtree tw-text-light-grey"
              >Submit your PTO</span
            >
          </div>
          <div class="tw-flex tw-items-center">
            <img
              src="@/assets/svgs/dates/checkmark.svg"
              alt="Checkmark"
              class="tw-mr-2"
            />
            <span class="tw-text-left tw-font-figtree tw-text-light-grey"
              >Suggest things to do</span
            >
          </div>
          <div class="tw-flex tw-items-center">
            <img
              src="@/assets/svgs/dates/checkmark.svg"
              alt="Checkmark"
              class="tw-mr-2"
            />
            <span class="tw-text-left tw-font-figtree tw-text-light-grey"
              >Hype up the crew</span
            >
          </div>
        </div>
        <p class="j-text-field-label tw-text-18 tw-font-bold tw-mt-6 tw-mb-0">
          Save on Travel
        </p>
        <p class="tw-text-left tw-text-xs tw-text-light-grey tw-mb-4">
          We earn a small amount when you buy through our links
        </p>
        <div class="tw-flex tw-justify-between">
          <!-- <a
            href="https://www.tkqlhce.com/click-101084691-15736720"
            target="_blank"
            rel="noopener noreferrer"
            class="tw-w-1/2 tw-mr-2"
          >
            <button
              class="tw-flex tw-items-center tw-justify-between tw-shadow-custom tw-rounded-xl tw-p-4 tw-w-full"
              type="button"
            >
              <span class="tw-text-left tw-text-dark-green tw-font-bold"
                >Hopper</span
              >
              <img
                class="tw-rounded-full"
                src="@/assets/svgs/arrow-right-dark-background.svg"
                alt="Go"
              />
            </button>
          </a> -->
          <a
            href="https://prf.hn/click/camref:1101l3BN3k/pubref:letsjetty/ar:app"
            target="_blank"
            rel="noopener noreferrer"
            class="tw-w-full"
          >
            <button
              class="tw-flex tw-items-center tw-justify-between tw-shadow-custom tw-rounded-xl tw-p-4 tw-w-full"
              type="button"
            >
              <span class="tw-text-left tw-text-dark-green tw-font-bold"
                >Expedia</span
              >
              <img
                class="tw-rounded-full"
                src="@/assets/svgs/arrow-right-dark-background.svg"
                alt="Go"
              />
            </button>
          </a>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { FormattedDateRange } from "@/mixins/FormattedDateRange.js";
import { DateTime } from "luxon";
export default {
  name: "DatesList",
  mixins: [FormattedDateRange],
  data() {
    return {
      dates: [],
      isOpen: null,
      minDate: DateTime.local().toISO(),
      loadedDates: false
    };
  },
  watch: {
    dates(val) {
      if (val[0] && val[1]) {
        let temp = [...val];
        if (new Date(val[0]) > new Date(val[1])) {
          this.dates[0] = temp[1];
          this.dates[1] = temp[0];
        }

        this.updateDates();
      }
    }
  },
  computed: {
    sessionUser() {
      return this.$store.state.auth.user;
    },
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    },
    isOwner() {
      if (this.trip) {
        return this.trip.ownerId === this.sessionUser.id;
      } else return null;
    },
    isOrganizer() {
      return this.sessionUser.trips
        .find((t) => t.id === this.trip.id)
        .trip_invite.roles.includes("owner");
    },
    daysToGo() {
      const now = DateTime.now();
      const start = DateTime.fromISO(this.trip.startDate, { setZone: true });
      const diff = start.diff(now, "days").toObject();
      const count = Math.round(diff.days);

      if (count >= 1) {
        return count;
      } else {
        return 0;
      }
    }
  },
  methods: {
    closePanel() {
      const panelRef = this.$refs["datePanel"];
      if (panelRef) this.isOpen = false;
    },
    updateDates() {
      this.$store.dispatch("trip/patch", [
        this.trip.id,
        {
          startDate: DateTime.fromISO(this.dates[0], { setZone: true })
            .startOf("day")
            .toISODate(),
          endDate: DateTime.fromISO(this.dates[1], { setZone: true })
            .endOf("day")
            .toISODate()
        }
      ]);

      if (sessionStorage.getItem("itinerary")) {
        this.$router.push({ name: "TripItineraryList" });
      }
    }
  },
  async beforeMount() {
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Dates",
      bgColor: "#E24BD6",
      fontColor: "#fff",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      tripHubButton: false,
      showBackButton: true
    });

    if (!this.trip) {
      // Dispatch the action to get trip details
      await this.$store.dispatch("trip/get", [
        this.$route.params.id,
        {
          query: {
            include: "destination,users,survey,trip_invitation"
          }
        }
      ]);
    }
    this.dates[0] = DateTime.fromISO(this.trip.startDate, {
      setZone: true
    }).toISODate();
    this.dates[1] = DateTime.fromISO(this.trip.endDate, {
      setZone: true
    }).toISODate();
    this.loadedDates = true;
  }
};
</script>
