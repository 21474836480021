<template>
  <div class="dates-list">
    <template v-if="isOwner || isOrganizer">
      <v-row>
        <v-col cols="12">
          <div class="tw-flex tw-items-center tw-justify-center">
            <img
              src="@/assets/svgs/dates/set-dates-calendar.svg"
              alt="set dates calendar"
            />
          </div>
          <h2
            class="tw-text-center tw-font-figtree tw-text-lg tw-font-semibold tw-text-dark-green tw-mt-4"
          >
            Set the Dates
          </h2>
          <v-expansion-panels
            v-model="isOpen"
            class="tw-rounded-md tw-mt-4"
            ref="datePanel"
          >
            <v-expansion-panel ref="datesPanel">
              <v-expansion-panel-header>
                <div
                  class="j-panel-header tw-flex tw-flex-row tw-items-center tw-justify-between"
                >
                  <v-icon>mdi-calendar-blank</v-icon>
                  <div
                    class="tw-text-charcoal tw-text-base tw-font-semibold tw-mx-auto"
                  >
                    <span v-if="dates.length > 0">{{
                      getFormattedDateRange(dates[0], dates[1])
                    }}</span>
                    <span v-else>Choose a date</span>
                  </div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-date-picker
                  @change="closePanel"
                  class="tw-py-4"
                  no-title
                  color="secondary"
                  v-model="dates"
                  range
                  :min="minDate"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="secondary"
                    @click="dates = []"
                    class="tw-tracking-normal"
                    >Reset Dates</v-btn
                  >
                </v-date-picker>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-btn
            x-large
            rounded
            block
            depressed
            class="j-btn tw-bg-chartreuse tw-mt-4 tw-tracking-normal"
            :disabled="!(dates[0] && dates[1])"
            @click="finalizeDates()"
            >Finalize Dates</v-btn
          >
        </v-col>
      </v-row>
      <v-row v-if="!trip.surveys || trip.surveys.length === 0">
        <v-col cols="12">
          <p
            class="tw-text-left tw-font-figtree tw-font-semibold tw-text-dark-green tw-mt-8 tw-mb-2"
          >
            Not sure yet?
          </p>
          <p class="tw-text-left tw-text-sm tw-text-light-grey">
            Figuring out everyone’s availability can be rough. Avoid the
            back-and-forth by sending a quick survey instead.
          </p>
          <v-btn
            block
            rounded
            x-large
            color="secondary"
            large
            class="tw-mt-14 tw-tracking-normal"
            @click="
              goTo({
                name: 'SurveyCreateRouteForm',
                params: { id: trip.id, selectedQuestion: 1 }
              })
            "
          >
            Send a Survey
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <div class="tw-flex tw-justify-center">
        <img src="@/assets/svgs/dates/sand-clock.svg" alt="Sand clock" />
      </div>
      <h2
        class="tw-text-center tw-font-figtree tw-text-lg tw-font-semibold tw-text-dark-green tw-mt-4"
      >
        Check back in later
      </h2>
      <p class="tw-text-center tw-text-base tw-text-light-grey">
        Your organizer is coordinating dates and may reach out to you for
        availability via the Trip Survey.
      </p>
      <v-btn
        block
        rounded
        x-large
        color="secondary"
        large
        class="tw-mt-6 tw-tracking-normal"
        @click="goTo({ name: 'TripsRoute' })"
      >
        Back to Trip Hub
      </v-btn>
    </template>
  </div>
</template>
<script>
import { FormattedDateRange } from "@/mixins/FormattedDateRange.js";
import { DateTime } from "luxon";
export default {
  name: "DatesList",
  mixins: [FormattedDateRange],
  data() {
    return {
      dates: [],
      isOpen: null,
      minDate: DateTime.local().toISODate()
    };
  },
  watch: {
    dates(val) {
      if (val[0] && val[1]) {
        let temp = [...val];
        if (new Date(val[0]) > new Date(val[1])) {
          this.dates[0] = temp[1];
          this.dates[1] = temp[0];
        }
      }
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    },
    isOwner() {
      if (this.trip) {
        return this.trip.ownerId === this.user.id;
      } else return null;
    },
    isOrganizer() {
      if (!this.trip || !this.user.trips || this.user.trips.length === 0)
        return false;
      let currentTrip = this.user.trips.find((t) => t.id === this.trip.id);
      if (!currentTrip) return false;
      return currentTrip.trip_invite.roles.includes("owner");
    }
  },
  methods: {
    closePanel() {
      const panelRef = this.$refs["datePanel"];
      if (panelRef) this.isOpen = false;
    },
    async finalizeDates() {
      this.$store
        .dispatch("trip/patch", [
          this.trip.id,
          {
            startDate: DateTime.fromISO(this.dates[0], { setZone: true })
              .startOf("day")
              .toISODate(),
            endDate: DateTime.fromISO(this.dates[1], { setZone: true })
              .endOf("day")
              .toISODate()
          }
        ])
        .then(() => {
          if (sessionStorage.getItem("itinerary")) {
            this.goTo({ name: "TripItineraryList" });
          } else {
            this.$router.replace({ name: "DatesListRoute" });
          }
        });
    },
    goTo(route) {
      this.$router.push(route);
    }
  },
  async beforeMount() {
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Dates",
      bgColor: "#E24BD6",
      fontColor: "#fff",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      tripHubButton: false,
      showBackButton: true
    });
    this.$store.commit("trip/clearAll", this.$store.state["trip"]);
    await this.$store.dispatch("trip/get", this.$route.params.id);
    if (this.trip && this.trip.startDate && this.trip.endDate) {
      this.dates = [
        DateTime.fromISO(this.trip.startDate, { setZone: true }).toISODate(),
        DateTime.fromISO(this.trip.endDate, { setZone: true }).toISODate()
      ];
    }
  },
  destroyed() {
    sessionStorage.removeItem("itinerary");
  }
};
</script>
